import { useFormHelper } from '~/composables/helpers/useFormHelper'

export const useAPI = async <DataT>(url: string, params: any) => {
	const { token} = useAuthState()
	const formHelper = useFormHelper()

	const config = useRuntimeConfig()

	const opts = {
		key: url,
		baseURL: config.public.baseURL,

		// @ts-ignore
		async onRequest({ options }) {
			options.headers = options.headers || {}

			if (token.value) {
				options.headers['Access-Control-Expose-Headers'] = '*'
				options.headers['Authorization'] = token.value
			}
		},
		...params
	}

	if (opts.body) {
		opts.body = formHelper.serialize(opts.body, {
			dotsForObjectNotation: false,
			nullsAsUndefineds: true,
			indices: true,
		})
	}

	return useFetch<DataT>(url, opts)
}